var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-4 text-left"},[_c('v-card-title',{staticClass:"pa-0"},[_vm._v("Transaction Details")]),_c('v-divider'),_c('div',{staticClass:"mt-3"},[_c('span',[_vm._v("Transaction Reference: ")]),_c('strong',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.copyToClipboard('trnRef', 'copied')}}},[_vm._v(" "+_vm._s(_vm.transaction.reference || _vm.transaction.requestRef)+" "),_c('input',{staticClass:"hide",attrs:{"id":"trnRef","readonly":""},domProps:{"value":_vm.transaction.reference || _vm.transaction.requestRef}}),_c('v-icon',{attrs:{"size":"18","color":"primary","title":"copy"}},[_vm._v(" mdi-content-copy ")]),_c('small',{staticClass:"error--text d-none",attrs:{"id":"copied"}},[_vm._v("copied!")])],1)]),_c('div',[_c('span',[_vm._v("Transaction Type: ")]),_c('strong',[_vm._v(_vm._s(_vm.transaction.type))])]),_c('div',[_c('span',[_vm._v("Amount: ")]),_c('strong',[_vm._v(_vm._s(_vm.helpers.formatAmount(_vm.transaction.amount)))])]),_c('div',[_c('span',[_vm._v("Balance: ")]),_c('strong',[_vm._v(_vm._s(_vm.helpers.formatAmount(_vm.transaction.balance)))])]),_c('div',[_c('span',[_vm._v("Activity: ")]),_c('strong',[_vm._v(_vm._s(_vm.transaction.activity))])]),(!_vm.bank_transfer)?_c('div',[_c('span',[_vm._v("Service Name")]),_c('strong',[_vm._v(" "+_vm._s(_vm.serviceType() + _vm.variationCode())+" ")])]):_vm._e(),(!_vm.bank_transfer)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.unique_element_title)+": ")]),_c('strong',[_vm._v(_vm._s(_vm.unique_element))])]):_vm._e(),(
          _vm.transaction.activity &&
          _vm.transaction.activity.indexOf('Electricity') > -1
        )?_c('div',[_c('span',[_vm._v("Token: ")]),_c('strong',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.copyToClipboard('token', 'copiedToken')}}},[_vm._v(" "+_vm._s(_vm.payload.resp.token)+" "),_c('input',{staticClass:"hide",attrs:{"id":"token","readonly":""},domProps:{"value":_vm.payload.resp.token || ''}}),_c('v-icon',{attrs:{"size":"18","color":"primary","title":"copy"}},[_vm._v(" mdi-content-copy ")]),_c('small',{staticClass:"error--text d-none",attrs:{"id":"copiedToken"}},[_vm._v("copied!")])],1)]):_vm._e(),(
          _vm.transaction.activity &&
          _vm.transaction.activity.indexOf('Electricity') > -1
        )?_c('div',[_c('span',[_vm._v("Units: ")]),_c('strong',[_vm._v(_vm._s(_vm.payload.resp.units))])]):_vm._e(),(_vm.bank_transfer)?_c('div',[(_vm.transaction.type === 'Debit')?_c('div',[_c('div',[_c('span',[_vm._v("Recipient Account Name: ")]),_c('strong',[_vm._v(_vm._s(_vm.recipient.account_name))])]),_c('div',[_c('span',[_vm._v("Recipient Account Number: ")]),_c('strong',[_vm._v(_vm._s(_vm.recipient.account_number))])]),_c('div',[_c('span',[_vm._v("Recipient Bank: ")]),_c('strong',[_vm._v(_vm._s(_vm.recipient.bank_name))])])]):_vm._e(),(_vm.transaction.type === 'Credit')?_c('div',[_c('div',[_c('span',[_vm._v("Sender Account Name: ")]),_c('strong',[_vm._v(_vm._s(_vm.sender.account_name))])]),_c('div',[_c('span',[_vm._v("Sender Account Number: ")]),_c('strong',[_vm._v(_vm._s(_vm.sender.account_number))])]),_c('div',[_c('span',[_vm._v("Sender Bank: ")]),_c('strong',[_vm._v(_vm._s(_vm.sender.bank_name))])])]):_vm._e()]):_vm._e(),_c('div',[_c('span',[_vm._v("Customer Name: ")]),_c('strong',[_vm._v(" "+_vm._s(_vm.customer.account_name)+" ")])]),_c('div',[_c('span',[_vm._v("Customer Account Number: ")]),_c('strong',[_vm._v(" "+_vm._s(_vm.customer.account_number)+" ")])]),_c('div',[_c('span',[_vm._v("Status: ")]),_c('strong',[_vm._v(" "+_vm._s(_vm.transaction.status)+" ")])]),_c('div',[_c('span',[_vm._v("Transaction Date: ")]),_c('strong',[_vm._v(" "+_vm._s(_vm.helpers.formatDate(_vm.transaction.createdAt))+" ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"close mr-3",attrs:{"depressed":""},on:{"click":_vm.closeDialog}},[_vm._v("Close")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }